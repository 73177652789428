<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>个人赛赛务管理</el-breadcrumb-item>
            <el-breadcrumb-item>个人赛程</el-breadcrumb-item>
            <el-breadcrumb-item>报名信息</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">报名信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row :gutter="0">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.season"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="所属赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.districtId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="赛程" prop="raceSchedule">
                            <el-select
                                placeholder="请选择"
                                @visible-change="changeRaceScheduleValue"
                                style="width: 100%"
                                v-model="queryForm.raceSchedule"
                                @change="raceScheduleClicked"
                                clearable
                            >
                                <el-option
                                    v-for="item in raceScheduleList"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校省市区" prop="schoolArea">
                            <el-cascader
                                placeholder="请选择"
                                v-model="queryForm.schoolArea"
                                ref="schoolAreaRef"
                                :options="areasData"
                                style="width: 100%"
                                :props="{
                                    expandTrigger: 'hover',
                                    label: 'name',
                                    value: 'areaId',
                                    children: 'areas',
                                }"
                                @change="provincesHandleChange"
                            ></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.schoolId"
                                @change="schoolSelectChange"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="item in provincesSchoolList"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="组别" prop="enrollmentLevel">
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.enrollmentLevel"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_group"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="IsPreliminaryfinals">
                        <el-form-item label="参赛方式" prop="isOnline">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.isOnline"
                            >
                                <el-option
                                    v-for="item in dict_isOnline"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <el-col :span="6" v-if="IsPreliminaryfinals">
                        <el-form-item label="开通类型" prop="openType">
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.openType"
                                clearable
                            >
                                <el-option
                                    v-for="item in p_open_type"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            queryForm.raceSchedule !== '1' &&
                            queryForm.raceSchedule !== '2'
                        "
                    >
                        <el-form-item label="查询签到" prop="seeSignUp">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                                v-model="queryForm.seeSignUp"
                            >
                                <el-option
                                    v-for="item in dict_seeSignUp"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            queryForm.raceSchedule !== '1' &&
                            queryForm.raceSchedule !== '2'
                        "
                    >
                        <el-form-item label="是否晋级" prop="promotion">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                                v-model="queryForm.promotion"
                            >
                                <el-option
                                    v-for="item in dict_isPromotion"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            queryForm.raceSchedule !== '1' &&
                            queryForm.raceSchedule !== '2'
                        "
                    >
                        <el-form-item label="是否签到" prop="signIn">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                                v-model="queryForm.signIn"
                            >
                                <el-option
                                    v-for="item in dict_signIn"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            queryForm.raceSchedule !== '1' &&
                            queryForm.raceSchedule !== '2'
                        "
                    >
                        <el-form-item
                            label="国赛资格"
                            prop="ableApplyNationmatch"
                        >
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.ableApplyNationmatch"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_qualification"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            queryForm.raceSchedule !== '1' &&
                            queryForm.raceSchedule !== '2'
                        "
                    >
                        <el-form-item
                            label="缴费状态"
                            prop="nationmatchIsPayed"
                        >
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.nationmatchIsPayed"
                                clearable
                            >
                                <el-option
                                    v-for="item in nationmatchIsPayedList"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="IBC选手" prop="ibcIdentity">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.ibcIdentity"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_countryReserve"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getDataList('search')"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 报名信息列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="6">
                    <span class="title_class">报名信息列表</span>
                </el-col>
                <el-col :span="18" style="text-align: right">
                  <el-button
                        size="small"
                        type="success"
                        @click="exportItsoInfoSignin"
                        v-if="queryForm.raceSchedule === '7'"
                        icon="el-icon-download"
                        plain
                        >导出国赛团体赛选手信息</el-button
                    >
                  <el-button
                        size="small"
                        type="danger"
                        @click="exportInfoSignin"
                        v-if="queryForm.raceSchedule === '7'"
                        icon="el-icon-download"
                        plain
                        >导出国赛选手信息</el-button
                    >
                    <el-button
                        type="primary"
                        size="small"
                        plain
                        @click="batchTransferDialogVisible = true"
                        >报名资格批量转移</el-button
                    >
                    <el-button
                        size="small"
                        type="primary"
                        @click="exportSignin"
                        icon="el-icon-download"
                        plain
                        >导出列表信息</el-button
                    >
                    <el-button
                        v-if="!IsPreliminaryfinals"
                        size="small"
                        type="success"
                        icon="el-icon-upload2"
                        @click="importScoreInfo"
                        plain
                        >成绩导入</el-button
                    >
                    <el-button
                        v-if="queryForm.raceSchedule === '7'"
                        size="small"
                        type="danger"
                        icon="el-icon-check"
                        @click="reportSignInClicked"
                        plain
                        >国赛报道签到</el-button
                    >
                    <el-button
                        v-if="
                            !IsPreliminaryfinals &&
                            queryForm.raceSchedule !== '6'
                        "
                        size="small"
                        type="warning"
                        icon="el-icon-finished"
                        @click="signInDialogVisibleClicked"
                        plain
                        >比赛签到</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                @sort-change="tableSortChange"
                ref="purchasePackage"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column
                    label="赛季"
                    prop="season"
                    width="80px"
                ></el-table-column>
                <el-table-column
                    label="赛区名称"
                    prop="districtName"
                ></el-table-column>
                <el-table-column
                    label="联系方式"
                    prop="phone"
                ></el-table-column>
                <el-table-column label="姓名" prop="nationalnum" width="120">
                    <template slot-scope="scope">
                        <el-link
                            style="color: #409eff"
                            @click="handleToUserDetails(scope.row.userId)"
                            >{{ scope.row.userName }}
                        </el-link>
                        {{
                            scope.row.nationalReserve === true
                                ? "(国赛保留)"
                                : ""
                        }}
                        {{ scope.row.ibcIdentity === true ? "(IBC)" : "" }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="国赛号码牌"
                    prop="nationalnum"
                    v-if="
                        queryForm.raceSchedule === '7' ||
                        queryForm.raceSchedule === '8'
                    "
                >
                    <template slot-scope="scope">
                        <span style="color: #ff0000; font-size: 20px">{{
                            scope.row.nationalnum
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="学校" prop="schoolName">
                    <template slot-scope="scope">
                        <span
                            v-if="
                                scope.row.fillschoolName &&
                                scope.row.fillschoolName !== ''
                            "
                            >{{ scope.row.fillschoolName }}</span
                        >
                        <span v-else>{{ scope.row.schoolName }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="组别"
                    prop="enrollmentLevel"
                    :formatter="groupFormatter"
                ></el-table-column>
                <el-table-column
                    label="资格年级"
                    prop="grade"
                    :formatter="gradeFormatter"
                ></el-table-column>
                <el-table-column
                    v-if="
                        queryForm.raceSchedule === '5' ||
                        queryForm.raceSchedule === '6' ||
                        queryForm.raceSchedule === '4'
                    "
                    label="系统年级"
                    prop="userInfoGrade"
                    :formatter="userInfoGradeFormatter"
                ></el-table-column>
                <el-table-column
                    label="产品名称"
                    key="productName"
                    prop="productName"
                    v-if="IsPreliminaryfinals"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.productName">{{
                            scope.row.productName
                        }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    key="openType"
                    v-if="IsPreliminaryfinals"
                    label="开通类型"
                    prop="openType"
                    :formatter="openTypeFormatter"
                    width="100px"
                ></el-table-column>
                <el-table-column
                    key="signIn"
                    label="是否签到"
                    prop="signIn"
                    v-if="!IsPreliminaryfinals && (queryForm.raceSchedule !== '7' && queryForm.raceSchedule !== '8')"
                >
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.signIn === true"
                            >已签到</el-tag
                        >
                        <el-tag type="danger" v-else>未签到</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否查询签到"
                    key="seeSignUp"
                    prop="seeSignUp"
                    v-if="!IsPreliminaryfinals && (queryForm.raceSchedule !== '7' && queryForm.raceSchedule !== '8')"
                >
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.seeSignUp === true"
                            >已查询</el-tag
                        >
                        <el-tag type="danger" v-else>未查询</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    key="isOnline"
                    label="参赛方式"
                    prop="isOnline"
                    width="125px"
                    v-if="IsPreliminaryfinals"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.score ? 'success' : 'danger'"
                            v-if="scope.row.isOnline + '' === '1'"
                            size="mini"
                            >线上参赛({{
                                scope.row.score ? "已答题" : "未答题"
                            }})</el-tag
                        >
                        <el-tag type="warning" v-else size="mini"
                            >线下参赛</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否晋级"
                    key="promotion"
                    prop="promotion"
                    v-if="
                        !IsPreliminaryfinals && queryForm.raceSchedule !== '8'
                    "
                >
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.promotion === 1"
                            >已晋级</el-tag
                        >
                        <el-tag
                            type="danger"
                            v-else-if="scope.row.promotion === 2"
                            >未晋级</el-tag
                        >
                        <el-tag type="warning" v-else>未设置</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="开通时间"
                    prop="createTime"
                    :key="Math.random()"
                ></el-table-column>
                <el-table-column
                    label="名次"
                    prop="promotionRank"
                    sortable="custom"
                    v-if="!IsPreliminaryfinals"
                    ><template slot-scope="scope">
                        <span type="success" v-if="scope.row.promotionRank">{{
                            scope.row.promotionRank
                        }}</span>
                        <span v-else type="danger" size="mini">暂无</span>
                    </template></el-table-column
                >
                <el-table-column
                    label="国赛资格"
                    v-if="!IsPreliminaryfinals && queryForm.raceSchedule !== '8'"
                >
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.ableApplyNationmatch"
                            size="mini"
                            >有资格</el-tag
                        >
                        <el-tag v-else type="danger" size="mini">无资格</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="国赛缴费状态"
                    v-if="!IsPreliminaryfinals && queryForm.raceSchedule !== '8'"
                >
                    <template slot-scope="scope">
                        <el-tag
                            type="warning"
                            v-if="scope.row.nationmatchIsPayed === 1"
                            size="mini"
                            >待确认缴费</el-tag
                        >
                        <el-tag
                            type="success"
                            size="mini"
                            v-else-if="scope.row.nationmatchIsPayed === 2"
                            >已缴费</el-tag
                        >
                        <el-tag v-else type="danger" size="mini">未缴费</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="260px"
                    :key="Math.random()"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="transferQualificationDig(scope.row.id)"
                            >资格转移</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            @click="removeQualificationDig(scope.row.id)"
                            >删除资格</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="changeGradeDig(scope.row)"
                            >修改年级</el-button
                        >
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" width="100px" v-else>
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            size="small"
                            @click="changeQualificationDig(scope.row)"
                            >晋级修改</el-button
                        >
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 批量上传成绩dialog -->
        <el-dialog
            title="批量上传成绩"
            :visible.sync="uploadScoreDialogVisible"
            width="30%"
            @close="uploadScoreDialogClose"
        >
            <div style="color: red">1.先导出“参赛信息列表”</div>
            <div style="color: red">
                2.完善信息表中的“成绩”，“是否晋级”列内容
            </div>
            <div style="color: red">3.上传完善好的文件</div>
            <span slot="footer" class="dialog-footer">
                <el-row>
                    <el-col
                        ><el-upload
                            :action="uploadFileRequest.uploadUrl"
                            ref="uploadScoreRef"
                            :headers="uploadFileRequest.headerObj"
                            :on-progress="uploadScoreProgress"
                            :on-success="uploadScoreSuccess"
                            :on-error="uploadScoreError"
                            :on-exceed="uploadScoreFileExceed"
                            :limit="1"
                            :auto-upload="false"
                        >
                            <el-button
                                slot="trigger"
                                size="small"
                                type="primary"
                                >选取文件</el-button
                            >
                            <el-button
                                style="margin-left: 10px"
                                size="small"
                                type="success"
                                @click="submitUpload"
                                >上传成绩</el-button
                            >
                        </el-upload></el-col
                    >
                </el-row>
            </span>
        </el-dialog>
        <!-- 比赛签到的dialog -->
        <el-dialog
            title="比赛签到"
            :visible.sync="signInDialogVisible"
            width="30%"
            @close="signInDialogClose"
        >
            <el-form
                :model="signInForm"
                ref="signInFormRef"
                :rules="signInFormRules"
                label-width="90px"
            >
                <el-form-item
                    label="赛区"
                    prop="districtId"
                    v-if="queryForm.raceSchedule !== '7'"
                >
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="signInForm.districtId"
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="组别" prop="level">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="signInForm.level"
                    >
                        <el-option
                            v-for="item in dict_group"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="goToSingIn()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 修改线上成绩的dialog -->
        <el-dialog
            title="修改成绩信息"
            :visible.sync="editScoreDialogVisible"
            @close="editScoreDialogClose"
            width="30%"
        >
            <el-form
                :model="editScoreForm"
                label-width="80px"
                ref="editScoreFormRef"
                :rules="editScoreFormRules"
            >
                <el-form-item label="姓名" prop="userName">
                    <el-input
                        v-model="editScoreForm.userName"
                        placeholder="请输入"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="晋级资格" prop="promotion">
                    <el-radio-group v-model="editScoreForm.promotion">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editScoreDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editScore">修 改</el-button>
            </span>
        </el-dialog>
        <!-- 批量转移资格 -->
        <el-dialog
            title="批量转移资格"
            :visible.sync="batchTransferDialogVisible"
            width="30%"
            @close="batchTransferDialogClose"
        >
            <el-form
                :model="batchTransferForm"
                label-width="80px"
                ref="batchTransferFormRef"
                :rules="batchTransferFormRules"
            >
                <el-form-item label="赛程" prop="raceSchedule">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="queryForm.raceSchedule"
                        disabled
                    >
                        <el-option
                            v-for="item in raceScheduleList"
                            :key="item.id"
                            :label="item.scheduleName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属赛区" prop="districtId">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="batchTransferForm.districtId"
                        @change="districtIdChange"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校名称" prop="schoolId">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="batchTransferForm.schoolId"
                        clearable
                        filterable
                    >
                        <el-option
                            v-for="item in provincesSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="batchTransferDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="batchTransferClicked"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 修改年级 -->
        <el-dialog
            title="修改年级"
            :visible.sync="showChangeGradeDialogVisible"
            @close="showChangeGradeDialogClose"
            width="30%"
        >
            <el-form
                :model="changeGradeForm"
                label-width="80px"
                ref="changeGradeFormRef"
                :rules="changeGradeFormRules"
            >
                <el-form-item label="姓名" prop="userName">
                    <el-input
                        v-model="changeGradeForm.userName"
                        placeholder="请输入"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="年级" prop="grade">
                    <el-select
                        v-model="changeGradeForm.grade"
                        placeholder="请选择"
                        @change="gradeChangeClicked"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in dict_grade"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showChangeGradeDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editGradeScore"
                    >修 改</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  queryTbEnrollmentList, getSchoolListWithCity, getAllAreaList, queryDistrictPage, removeQualificationQuest, transferQualificationQuest,
  setTbEnrollmentPromotion, transferBatch, updateLevel
} from '@/http/api'
export default {
  data () {
    return {
      editScoreFormRules: {
        promotion: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      showChangeGradeDialogVisible: false,
      editScoreForm: {},
      batchTransferDialogVisible: false,
      editScoreDialogVisible: false,
      signInDialogVisible: false,
      signInForm: {
        activityType: this.$chnEngStatusCode.activityType,
        districtId: null,
        level: ''
      },
      changeGradeForm: {
        enrollmentLevel: '',
        grade: null
      },
      changeGradeFormRules: {
        grade: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      signInFormRules: {
        districtId: [{ required: true, message: '请选择赛区', trigger: 'change' }],
        level: [{ required: true, message: '请选择组别', trigger: 'change' }]
      },
      batchTransferFormRules: {
        raceSchedule: [{ required: true, message: '请选择', trigger: 'change' }],
        districtId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      batchTransferForm: {
        activityType: this.$chnEngStatusCode.activityType,
        districtId: null,
        raceSchedule: this.$route.query.raceSchedule,
        schoolId: null,
        schoolArea: []
      },
      uploadScoreDialogVisible: false,
      timer: new Date().getTime(),
      getGradeData: this.$userInfo.getGradeData(),
      dict_isOnline: this.$userInfo.dict_isOnline(),
      dict_isPromotion: this.$userInfo.isPromotion(),
      dict_group: this.$userInfo.dict_group(),
      dict_signIn: this.$userInfo.dict_signIn(),
      dict_qualification: this.$userInfo.dict_qualification(),
      p_open_type: this.$userInfo.getDataList(this, 'p_open_type').then(value => { this.p_open_type = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      areasData: [],
      queryForm: {
        promotion: undefined,
        districtId: null,
        phone: '',
        name: '',
        season: this.$chnEngStatusCode.defaultSeason,
        group: '',
        schoolId: '',
        pageNum: 1,
        pageSize: 10,
        raceSchedule: this.$route.query.raceSchedule + '',
        userName: ''
      },
      nationmatchIsPayedList: [
        {
          dictCode: 0,
          dictLabel: '未缴费',
          dictValue: 0
        },
        {
          dictCode: 1,
          dictLabel: '未确认缴费',
          dictValue: 1
        },
        {
          dictCode: 2,
          dictLabel: '已缴费',
          dictValue: 2
        }
      ],
      dict_seeSignUp: [
        {
          dictCode: '1',
          dictLabel: '已查询',
          dictValue: true
        },
        {
          dictCode: '2',
          dictLabel: '未查询',
          dictValue: false
        }
      ],
      dict_countryReserve: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: true
        },
        {
          dictCode: 2,
          dictLabel: '否',
          dictValue: false
        }
      ],
      total: 0,
      dict_grade: this.$userInfo.getGradeData(),
      dict_gender: this.$userInfo.getDataList(this, 't_gender').then(value => { this.dict_gender = value }),
      dataList: [],
      provincesSchoolList: [],
      dict_district: [],
      raceScheduleList: [],
      IsPreliminaryfinals: this.getIsPreliminaryfinals(),
      uploadFileRequest: {
        upLoadType: 'file',
        uploadUrl: this.$env.baseIP + 'match/erp/importQuarterFinalsScore',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      }
    }
  },
  created () {
    this.getDistrictList()
    this.getRaceScheduleList()
    this.getAllAreaList()
  },
  methods: {
    // 获取是否是初复赛
    getIsPreliminaryfinals () {
      console.log('this.queryForm', this.queryForm)
      var scheduleId = Number((this.queryForm && this.queryForm.raceSchedule) ? this.queryForm.raceSchedule : this.$route.raceSchedule)
      if (scheduleId === 1 || scheduleId === 2) {
        return true
      } else {
        return false
      }
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取赛程
    getRaceScheduleList () {
      this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(data => {
        this.raceScheduleList = data
        this.getDataList()
      })
    },
    // 获取报名列表
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      this.IsPreliminaryfinals = this.getIsPreliminaryfinals()
      queryTbEnrollmentList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    importScoreInfo () {
      this.uploadScoreDialogVisible = true
    },
    uploadScoreDialogClose () {
      this.$refs.uploadScoreRef.clearFiles()// 清除文件
    },
    uploadScoreProgress () {
      this.$common.openLoading()
    },
    uploadScoreSuccess (res) {
      console.log('uploadScoreSuccess', res)
      if (res.code !== 0) {
        this.$refs.uploadScoreRef.clearFiles()// 清除文件
        return this.$alert('请修改后再上传，失败原因：' + res.message, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('上传成绩成功')
      this.getDataList()
      this.uploadScoreDialogVisible = false
    },
    uploadScoreError (error) {
      this.$message.error(error)
    },
    uploadScoreFileExceed (files, fileList) {
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.uploadScoreRef.clearFiles()// 清除文件
      this.$refs.uploadScoreRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    signInDialogClose () {
      this.$refs.signInFormRef.resetFields()
    },
    submitUpload () {
      if (this.$refs.uploadScoreRef.uploadFiles.length === 0) {
        return this.$message.warning('请选择要上传的文件')
      }
      this.$confirm('请再次确认所选文件是否正确,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$refs.uploadScoreRef.submit()
      }).catch(() => { })
    },
    signInDialogVisibleClicked () {
      console.log('this.queryForm', this.queryForm)
      if (this.queryForm.raceSchedule !== '8') {
        this.signInDialogVisible = true
        this.signInForm.districtId = this.queryForm.districtId
      } else {
        var matchTypeName = this.matchTypeFormatter(this.queryForm)
        var groupName = ''
        this.dict_group.forEach(element => {
          if (element.dictValue === this.signInForm.level) {
            groupName = element.dictLabel
          }
        })
        var turnModel = { ...this.queryForm, ...{ matchTypeName: matchTypeName, groupName: groupName, activityType: this.activityType, enrollmentLevel: this.signInForm.level }, ...this.signInForm }
        this.$router.push({
          path: '/individual_racing_schdules/sspcn_signinDetail',
          query: {
            division: JSON.stringify(turnModel)
          }
        })
      }
    },
    goToSingIn () {
      this.$refs.signInFormRef.validate(async valid => {
        if (!valid) return
        this.signInDialogVisible = false
        // 组织跳转的数据
        var divisionName = ''
        this.dict_district.forEach(element => {
          if (element.dictValue === this.signInForm.districtId) {
            divisionName = element.dictLabel
          }
        })
        // var matchTypeName = '城市半决赛'
        var groupName = ''
        this.dict_group.forEach(element => {
          if (element.dictValue === this.signInForm.level) {
            groupName = element.dictLabel
          }
        })
        var matchTypeName = this.matchTypeFormatter(this.queryForm)
        var turnModel = { ...this.queryForm, ...{ matchTypeName: matchTypeName, groupName: groupName, divisionName: divisionName, activityType: this.activityType, enrollmentLevel: this.signInForm.level }, ...this.signInForm }
        console.log('turnModel', turnModel)
        this.$router.push({
          path: '/individual_racing_schdules/sspcn_signinDetail',
          query: {
            division: JSON.stringify(turnModel)
          }
        })
      })
    },
    tableSortChange (column) {
      console.log('column', column)
      this.queryForm.sort = column.order === 'descending' ? 'desc' : 'asc'
      this.queryForm.sortName = 'promotionRank'
      this.getDataList()
    },
    changeQualificationDig (rowData) {
      this.editScoreForm = rowData
      this.editScoreForm.userName = rowData.userName ? rowData.userName : rowData.scoreName
      this.editScoreForm.raceSchedule = rowData.raceSchedule
      this.editScoreForm.userId = rowData.userId ? rowData.userId : rowData.id
      this.editScoreDialogVisible = true
    },
    editScore () {
      setTbEnrollmentPromotion(this.editScoreForm).then((res) => {
        this.editScoreDialogVisible = false
        this.$message.success('修改成功')
        this.getDataList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    editScoreDialogClose () {
      this.$refs.editScoreFormRef.resetFields()
    },
    districtIdChange (value) {
      console.log('value', value)
      getSchoolListWithCity({ districtId: value }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
        this.batchTransferForm.schoolId = null
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 批量转移资格
    batchTransferClicked () {
      this.$refs.batchTransferFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('该操作不可逆,请谨慎操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          transferBatch(this.batchTransferForm).then((res) => {
            this.batchTransferDialogVisible = false
            this.$message({
              message: '转移成功',
              type: 'success',
              center: true,
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => { })
      })
    },
    // 修改年级点击事件
    changeGradeDig (rowData) {
      var selectData = JSON.parse(JSON.stringify(rowData))
      this.changeGradeForm.id = selectData.id
      this.changeGradeForm.userName = selectData.userName
      this.changeGradeForm.enrollmentLevel = selectData.enrollmentLevel
      this.changeGradeForm.grade = selectData.grade
      this.showChangeGradeDialogVisible = true
    },
    showChangeGradeDialogClose () {
      this.$refs.changeGradeFormRef.resetFields()
    },
    gradeChangeClicked (val) {
      console.log('val', val)
      const gradeData = this.$userInfo.getUserGroupWithGrade(val)
      this.changeGradeForm.grade = val
      this.changeGradeForm.enrollmentLevel = gradeData.groupId
    },
    editGradeScore () {
      console.log('this.changeGradeForm', this.changeGradeForm)
      updateLevel(this.changeGradeForm).then((res) => {
        this.showChangeGradeDialogVisible = false
        if (res.code === 3094) {
          this.$confirm(res.msg, '提示', {
            confirmButtonText: '确定修改',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.changeGradeForm.createcompetitionNumberType = false
            this.editGradeScore()
          }).catch(() => { })
        } else {
          this.getDataList()
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },

    // 重置搜索条件
    reset () {
      this.queryForm.pageNum = 1
      this.$refs.queryFormRef.resetFields()
      this.signInForm.districtId = null
      this.getDataList()
    },
    batchTransferDialogClose () {
      this.$refs.batchTransferFormRef.resetFields()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.gender + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    userInfoGradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.userInfoGrade) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    openTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    matchTypeFormatter (data) {
      console.log('data', data)
      console.log('this.raceScheduleList', this.raceScheduleList)
      var temp = '未设置'
      for (const key in this.raceScheduleList) {
        if (Object.hasOwnProperty.call(this.raceScheduleList, key)) {
          const element = this.raceScheduleList[key]
          if (element.id === data.raceSchedule) {
            temp = element.scheduleName
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.enrollmentLevel) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    promotionFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_isPromotion) {
        if (Object.hasOwnProperty.call(this.dict_isPromotion, key)) {
          const element = this.dict_isPromotion[key]
          if (element.dictValue === data.promotion + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    signInFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_signIn) {
        if (Object.hasOwnProperty.call(this.dict_signIn, key)) {
          const element = this.dict_signIn[key]
          if (element.dictValue === data.signIn) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 赛程选择校验
    changeRaceScheduleValue (state) {
      if (state === true && this.queryForm.season === '') {
        this.$message.error('请先选择赛季')
      }
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.provincesSchoolList = []
      this.queryForm.schoolName = ''
      this.queryForm.schoolId = ''
      this.queryForm.provinceCode = value[0]
      this.queryForm.cityCode = value[1]
      this.queryForm.areaCode = value[2]
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校选择赋值schoolId
    schoolSelectChange (schoolid) {
      this.queryForm.schoolId = schoolid
    },
    handleToUserDetails (userId) {
      this.$router.push({
        path: '/c_data_Management/c_player_registInfo_details',
        query: { userId }
      })
    },
    exportSignin () {
      // const { pageNum, pageSize, ...newForm } = this.queryForm
      // const downLoadUrl = `${this.$http.defaults.baseURL}enrollment/excelQueryTbEnrollmentList?${this.$xcUtils.serialize(newForm)}`
      // this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl)
      var downForm = JSON.parse(JSON.stringify(this.queryForm))
      this.$delete(downForm, 'schoolArea')
      var downLoadUrl = this.$env.baseIP + 'enrollment/excelQueryTbEnrollmentList'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, downForm)
    },
    exportInfoSignin () {
      var downForm = JSON.parse(JSON.stringify(this.queryForm))
      var downLoadUrl = this.$env.baseIP + 'national/exportNationPlayer'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, downForm)
    },
    exportItsoInfoSignin () {
      var downForm = JSON.parse(JSON.stringify(this.queryForm))
      var downLoadUrl = this.$env.baseIP + 'national/exportItsoNationPlayer'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, downForm)
    },
    removeQualificationDig (dataId) {
      this.$confirm('此操作将删除该选手参赛资格, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.removeQualification(dataId)
      }).catch(() => {
      })
    },
    // 删除资格
    removeQualification (dataId) {
      removeQualificationQuest({ id: dataId }).then((res) => {
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    transferQualificationDig (dataId) {
      this.$confirm('此操作将该选手参赛资格转移到当前用户完善信息的赛区和学校, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.transferQualification(dataId)
      }).catch(() => {
      })
    },
    // 删除资格
    transferQualification (dataId) {
      transferQualificationQuest({ id: dataId }).then((res) => {
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    raceScheduleClicked (value) {
      console.log('value', value)
      this.queryForm.raceSchedule = value
      this.IsPreliminaryfinals = this.getIsPreliminaryfinals()
    },
    // 国赛报道签到
    reportSignInClicked () {
      this.$router.push({
        path: '/individual_racing_schedules/national_finals_reportSignIn'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title_class {
    display: -moz-inline-box;
    display: inline-block;
    width: 120px;
}
</style>
